import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Link } from "gatsby";
// import Img from "gatsby-image";

export default (props) => (
  <StaticQuery
    query={graphql`
      query Cards {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(areas)/"}}, sort: {order: ASC, fields: frontmatter___title}) {
          nodes {
            frontmatter {
              title
              path
              tags
              featuredImage {
                publicURL
                childImageSharp {
                  fluid(maxHeight: 600, maxWidth: 600, quality: 70, cropFocus: ATTENTION) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      
      const pages = data.allMarkdownRemark.nodes;

     

      var pagelist = pages.map(function(page){
        if (page.frontmatter.tags.includes(props.tag) && props.exclude !== page.frontmatter.path) {

          // var alt = 'Photo of ' + page.frontmatter.title;
          return (

            <div className="card">
              <Link to={page.frontmatter.path}><img src={page.frontmatter.featuredImage.childImageSharp.fluid.src} alt="" /><span className="linkText">{page.frontmatter.title}</span></Link>
            </div>

          )
        } else if (props.tag == 'all') {
          // var alt = 'Photo of ' + page.frontmatter.title;
          return (

            <div className="card">
              <Link to={page.frontmatter.path}><img src={page.frontmatter.featuredImage.childImageSharp.fluid.src} alt="" /><span className="linkText">{page.frontmatter.title}</span></Link>
            </div>

          )
        } else {
          return null;
        }
      })

      return (
        <section className="cards">
          <div className="wrap">{pagelist}</div>
        </section>
      )
      
    }}
  />
)