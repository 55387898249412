import React from "react"
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga';
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import DOD from './dods';
import ReCAPTCHA from "react-google-recaptcha";

Modal.setAppElement('#___gatsby')

export default class DonateInquiryForm extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      sent: false,
      error: false,
      errorMSG: null,
      validEmail: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Donate Now Inquiry'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  validateEmail(event) {
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    event.preventDefault();
    const { name, value } = event.target;

    if (validEmailRegex.test(value)) {
      this.setState({ validEmail: true });
    } else {
      this.setState({ validEmail: false });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.validEmail) {
     
      const data = new FormData(event.target);
      
      // fetch('http://uawebstg.fullerton.edu/campaign/_api/index.php', {
      fetch('https://campaign.fullerton.edu/_api/index.php', {
        method: 'POST',
        body: data,
      }).then((resp)=>{ 
        return resp.json() 
      }).then((json)=>{ 
        // console.log(json)
        if(json.success) {
          this.setState({ sent: true });
          ReactGA.event({
            category: 'Form',
            action: 'User completed signup form',
            label: 'Donate Inquiry (via ' + this.props.title + ')'
          });
          this.setState({ error: false })
        } else {
          this.setState({ error: true });
          this.setState({ sent: false });
          this.setState({ errorMSG: json.errorMSG });
        }
      });
      // .then((response) => { 
      //   console.log(response);
      //   if(response) {
      //     const sentStatus = this.state.sent;
      //     this.setState({ sent: !sentStatus });
      //   } else {
      //     const errorStatus = this.state.error;
      //     this.setState({ error: !errorStatus });
      //   }
      // });
    } else {
      this.setState({ error: true });
      this.setState({ sent: false });
      this.setState({ errorMSG: 'Please enter a valid email address.' });
    }
  }

  render() {

    if (this.props.contactreason) {
      var contactRE = this.props.contactreason;
    } else {
      var contactRE = 'about your gift'
    }

    return (
      <React.Fragment>
      <button className="ctaButton" onClick={this.openModal}><span><span><span>{this.props.ctaText}</span></span></span></button>
      <Modal
        closeTimeoutMS={400}
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Donate Now"
        className="modal donateInquiry"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={true}
      >
        <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
        <div className="wrap">
          <p style={{paddingBottom: '5px'}}>Please enter your information below and <DOD college={this.props.college} layout="name-only" />, <span className="donateInquiry--dod-title"><DOD college={this.props.college} layout="title-only" /></span>,  will contact you {contactRE}.</p>
          <p style={{paddingBottom: '5px'}}><small>Fields marked <span className="required">*</span> are required.</small></p>
          <form onSubmit={this.handleSubmit}>
            <label htmlFor="name">Your Name: <span className="required">*</span></label>
            <input type="text" name="name" id="name" required />

            <label htmlFor="phone">Your Phone Number: </label>
            <input type="tel" name="phone" id="phone" />

            <label htmlFor="email">Email: <span className="required">*</span></label>
            <input type="email" name="email" id="email" required onChange={this.validateEmail} />

            <label htmlFor="comments">Comments:</label>
            <textarea name="comments" id="comments"></textarea>

            <input type="hidden" name="fund" value={this.props.title} />
            <input type="hidden" name="action" value="donateInquiry" />
            <DOD college={this.props.college} layout="email-hidden-input" />

            <ReCAPTCHA
              style={{overflow: 'hidden'}}
              sitekey="6LcHSd0UAAAAABADqsv4SgrumUoMGatu246fUJ1l"
            />
            
            <br/>

            <input type="submit" value="Send" />
          </form>
          <CSSTransition
          in={this.state.sent}
          classNames={{
            enter: "animated",
            enterActive: "animated fadeIn open",
            enterDone: 'animated fadeIn open',
            exit: 'animated fadeOut open',
            exitActive: 'animated fadeOut open',
            exitDone: 'animated fadeOut open'
          }}
          >
            <span className="success">Thank you, your message has been sent. <DOD college={this.props.college} layout="name-only" /> will be in touch.</span>
          </CSSTransition>
          <CSSTransition
          in={this.state.error}
          classNames={{
            enter: "animated",
            enterActive: "animated fadeIn open",
            enterDone: 'animated fadeIn open',
            exit: 'animated fadeOut open',
            exitActive: 'animated fadeOut open',
            exitDone: 'animated fadeOut open'
          }}
          >
            <span className="error">{this.state.errorMSG}</span>
          </CSSTransition>
        </div>
      </Modal>
      </React.Fragment>
    );
  }
}