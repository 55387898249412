import React from "react";
import DonateInquiry from "./donate-inquiry-form";
import { CSSTransition } from 'react-transition-group';

export default class heroBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      loading: false
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.showLoading = this.showLoading.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  showLoading() {
    // alert('Hey!')
    this.setState({ loading: true });
  }

  updateDimensions = () => {
    var newHeight = window.innerHeight;
    var newHeight = window.innerHeight / 2;
    this.setState({ height: newHeight });
  };

  render() {

    // console.log(this.state.height);
    var heroHeight = {
      height: this.state.height
    }

    var subtext = null;
    if (this.props.subtext) {
      subtext = <p className="subtitle animated fadeInDown">{this.props.subtext}</p>
    }

    var button = null;
    if (this.props.extrabutton) {
      button = <a href={this.props.extrabuttonLink} class="ctaButton"><span><span><span>{this.props.extrabutton}</span></span></span></a>
    }

    var cta = null;
    if (this.props.ctaLink) {
      cta = 
      <React.Fragment><a onClick={this.showLoading} href={this.props.ctaLink}  className="ctaButton"><span><span><span>{this.props.ctaText}</span></span></span></a><CSSTransition
      in={this.state.loading}
      classNames={{
        enter: "animated",
        enterActive: "animated fadeIn show",
        enterDone: 'animated fadeIn show',
        exit: 'animated fadeOut show',
        exitActive: 'animated fadeOut show',
        exitDone: 'animated fadeOut show'
      }}
      >
        <div className="loading">
          <svg className="loading-spinner" viewBox="0 0 50 50" role="img" aria-labelledby="loading-spinner-title loading-spinner-desc">
            <title id="loading-spinner-title">Circle</title>
            <desc id="loading-spinner-desc">Spinning orange circle to indicate loading.</desc>
            <circle role="presentation" class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
          <span id="spinner-loading-text">Loading...</span>
        </div>
      </CSSTransition>
      </React.Fragment>
    } else if (this.props.ctaLink == null && this.props.ctaText){
      cta = <DonateInquiry ctaText={this.props.ctaText} title={this.props.heading} college={this.props.college} contactreason={this.props.contactreason} />
    }

    return (
      <div
        className="heroBanner"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundImage: `url(${this.props.image})`
        }}>
        <div className="text">
          <h1 className="animated fadeInDown">{this.props.heading}</h1>
          {subtext}
          {cta}
          {button}
        </div>
      </div> 
      // <Parallax
      //   className="heroBanner"
      //   bgImage={this.props.image}
      //   bgImageAlt={this.props.alt}
      //   strength={400}
      // >
      //   <div className="text">
      //     <h1 className="animated fadeInDown">{this.props.heading}</h1>
      //     {subtext}
      //     {cta}
      //   </div>
      //   <div className="heightControl" />
      // </Parallax>
    );
  }
}